import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Container, Grid, Box, Heading, Text } from "@theme-ui/components"
import PageHeader from "../components/pageHeader"
import Img from "gatsby-image"
import {
  getBlogPath,
  getNewsArticleNewPath,
  getNewsPath,
} from "../components/pathUtils"
import { LanguageContext } from "../locales/langContext"
import Seo from "../components/seo"

const Blog = ({ data: { articles, site }, pageContext, location }) => {
  const lang = pageContext.locale

  const i18nPaths = site.locales
    .filter(locale => locale !== "fr")
    .map(locale => {
      return {
        locale: locale,
        value:
          pageContext.category === "blog"
            ? getBlogPath(locale)
            : getNewsPath(locale),
      }
    })

  return (
    <Layout
      lang={lang}
      title={pageContext.title}
      location={location}
      i18nPaths={i18nPaths}
    >
      <Seo lang={lang} title={`${pageContext.title} - Gavazzi S.p.A.`} />
      <PageHeader title={pageContext.title} />
      <Container>
        <Grid columns={[0, 0, 2, 2]}>
          <LanguageContext.Consumer>
            {t =>
              articles.nodes.length > 0 ? (
                articles.nodes.map(article => (
                  <Box
                    sx={{
                      borderRadius: "sm",
                      overflow: "hidden",
                      a: {
                        textDecoration: "none",
                      },
                    }}
                  >
                    <Link
                      to={getNewsArticleNewPath(
                        article,
                        pageContext.category,
                        lang
                      )}
                    >
                      <Img fluid={article.heroImage.fluid} />
                      <Box
                        sx={{
                          position: "relative",
                          top: "-2rem",
                          left: "2rem",
                          background: "white",
                          padding: "1rem 2rem",
                          lineHeight: 1.3,
                        }}
                      >
                        <Heading as="h2" variant="h3" sx={{ color: "primary" }}>
                          {article.title}
                        </Heading>
                        <Text>{article.meta.firstPublishedAt}</Text>
                      </Box>
                    </Link>
                  </Box>
                ))
              ) : (
                <h2>{t.noArticle}</h2>
              )
            }
          </LanguageContext.Consumer>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query BlogQuery($locale: String!, $category: String!) {
    articles: allDatoCmsArticle(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: {
        slug: { ne: null }
        locale: { eq: $locale }
        category: { eq: $category }
      }
    ) {
      nodes {
        title
        originalId
        slug
        text
        ...ArticleAllSlugLocales
        heroImage {
          fluid(imgixParams: { fit: "crop", w: "600", h: "300" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        meta {
          firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
        }
      }
    }
    site: datoCmsSite {
      locales
    }
  }
`
