import React from "react"
import styled from "styled-components"
import { Row, Col } from "react-grid-system"
import { Display, Lead } from "./typography"
import titleBorder from "../images/title-border.svg"
import Nav from "./nav"
import { Container } from "@theme-ui/components"
import DesignTokens from "./designTokens"

const PageHeader = ({ title, subtitle, highlight, big }) => (
  <Wrapper>
    <Container>
      <Row>
        <Col>
          <StyledBox>
            <Display as="h1" color={DesignTokens.colors.primary[500]}>
              {title}
            </Display>
            {subtitle && (
              <CustomLead as="h2" color="white">
                {subtitle}
              </CustomLead>
            )}
          </StyledBox>
        </Col>
      </Row>
    </Container>
    <Nav />
  </Wrapper>
)
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 10rem;
  padding-bottom: 2rem;
`

const CustomLead = styled(Lead)`
  text-transform: ${props => (props.big ? "none" : "uppercase")};
  font-size: 24px;
`

const StyledBox = styled.div`
  padding-top: 3rem;
  background-image: url(${titleBorder});
  background-repeat: no-repeat;
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default PageHeader
